import React from "react";
import "./App.css";
import config from "./config";

const App: React.FC = () => {
  const downloadContact = () => {
    window.location.href = config.contactCardURL;
  };

  return (
    <div
      className={`${config.backgroundColor} flex justify-center items-center min-h-screen p-12`}
    >
      <div className="text-center max-w-xs w-full">
        <img
          src={config.photoURL}
          alt="Profile"
          className="w-11/12 rounded-2xl mx-auto mb-4 border border-gray-300"
        />
        <h1 className={`${config.headerTextColor} text-2xl font-semibold`}>
          {config.name}
        </h1>
        <h3 className={`${config.headerTextColor} text-md`}>{config.title}</h3>

        <button
          onClick={downloadContact}
          className={`${config.primaryColor} ${config.textColor} w-11/12 mt-3 p-4 rounded-md border border-gray-300`}
        >
          Contact Card
        </button>

        {config.links.map((link, index) => (
          <a
            key={index}
            href={link.url}
            className={`${config.primaryColor} ${config.textColor} w-11/12 mt-3 p-4 inline-block rounded-md border border-gray-300`}
          >
            {link.text}
          </a>
        ))}
      </div>
    </div>
  );
};

export default App;
