interface Link {
  text: string;
  url: string;
}

interface Config {
  name: string;
  title: string;
  photoURL: string;
  contactCardURL: string;
  primaryColor: string;
  secondaryColor: string;
  headerTextColor: string;
  textColor: string;
  backgroundColor: string;
  links: Link[];
}

const config: Config = {
  name: "Zachary Parrish",
  title: "Software Engineer",
  photoURL: "profile.jpg",
  contactCardURL: "contact.vcf",
  primaryColor: "bg-primary",
  secondaryColor: "bg-secondary",
  headerTextColor: "text-headerTextColor",
  textColor: "text-textColor",
  backgroundColor: "bg-backgroundColor",
  links: [
    {
      text: "GitHub",
      url: "https://github.com/0xModene",
    },
    {
      text: "Instagram",
      url: "https://instagram.com/zacharyparrish1559",
    },
    {
      text: "LinkedIn",
      url: "https://linkedin.com/in/zacharyparrish",
    },
    {
      text: "Real Estate",
      url: "https://lowertowns.com/",
    },
  ],
};

export default config;
